.main {
	display: flex;
	justify-content: space-between;
}

.left {
	padding:1.5em;
}

.right {

	padding: 1em;
}



