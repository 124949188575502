.main {
	text-align: center;
}
.main p{
	padding: 1.5em 2em;
}

.menu{
	display: flex;
	flex-wrap: wrap;
	margin: 2em 3em ;	
	justify-content: space-evenly;
	gap: 1em;
}

.card{
	tex-align: center;
	max-width: 300px;
}

.thumb{
	background-color: black;
	height: 150px;
	width: 250px;
	margin:1em auto;
	border-radius: 20px;
	display:flex;
	justify-content: center;
	align-items: center;
}

.thumbDark{
	background-color: white;
	height: 150px;
	width: 250px;
	margin:1em auto;
	border-radius: 20px;
	display:flex;
	justify-content: center;
	align-items: center;
	
}


.card h1 {
	font-size:1.65em;
	font-weight: 600;
}
